.video-gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 1500px; /* Ajusta el ancho máximo según tus necesidades */
  margin: 0 auto; /* Centra el contenedor */
  margin-top: 40px;
  margin-bottom: 40px;
}


.video-title {
  font-size: 1.5em; /* Puedes ajustar el tamaño según tus preferencias */
  font-family: 'GarnetLight';
  margin-top: 20px;
  margin-bottom: 20px;
  color: #a0f000; 
}


.video-description {

  font-family: 'RNSMiles';
  font-size: 1.2em; 
}



/* Estilos para la barra de tiempo del reproductor de video */
.react-player__progress {
  background-color: green !important; /* !important para asegurar la especificidad */
}

.react-player__played {
  background-color: green !important;
}




.video-item {
  width: calc(33.33% - 20px); /* Utiliza un tercio del ancho, restando el espacio entre elementos */
  border: 1px solid #ddd;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Espacio inferior entre elementos */

  background-color: #182329;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #555;


}





.video-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 10.25%; /* Proporción de aspecto 16:9 para el contenedor de video */
  border-radius: 8px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Media query para dispositivos con un ancho máximo de 767px (puedes ajustar este valor según tus necesidades) */
@media (max-width: 767px) {
  .video-item {
    width: calc(100% - 20px); /* Utiliza el ancho completo en dispositivos móviles */
  }
}



/* Cambiar el color de la barra de avance a verde */
.video-wrapper .react-player .progress-bar {
  background-color: green;
}