
.header-menu li.active > a {
  color: #a0f000;
}

.header-menu ul {
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  list-style: none;
  display: flex;
  font-size: 12px;
  font-weight: bold;
}

@media (max-width: 1470px) {
  .header-menu ul {
    display: none;
  }
}

.header-content {
  font-family: 'Lumier';
}

.header-menu li {
  font-size: 12px;
  white-space: nowrap; 
}




.header-menu a {
  position: relative;
  text-decoration: none;
  color: #fff;
  transition: color 0.3s;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}





.header-menu a:hover {
  color: #a0f000;
}

.header-menu a::before {
  content: '';
  width: calc(100% - 41px);
  height: 2px;
  background-color: #a0f000;
  position: absolute;
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.2s ease-out;
}

.header-menu a:hover::before {
  transform: translateX(-50%) scaleX(1);
}





.submenu a {
  color: #fff;
  text-decoration: none;
  display: block;
  font-weight: bold;
  padding: 5px;
  padding-bottom: 5px;
  z-index: 34!important;
}

.header-menu li:hover .submenu,
.header-menu li .submenu:focus {
  opacity: 1;
  pointer-events: auto;
  top: 94%;
  left: 19%;
  padding-bottom: 25px;
  

}

.header-menu li:not(:hover) .submenu,
.header-menu li .submenu:focus {
  opacity: 0;
  pointer-events: none;
  top: 100%;
  left: 19%;
  padding-bottom: 25px;
 
}

.submenu a::before {
  content: none;


}

.submenu li {
  display: flex;
  align-items: center;
  padding-bottom: 35px;
  z-index: 34!important;

  
}

.submenu li::before {
  padding-left: 10px;
  content: '\2022';
  color: #a0f000;
  font-size: 22px;
 
 
  
}


