.containercontactoinfo {
  margin-top: 60px;
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  flex-wrap: wrap;


  
}

@media (max-width: 768px) {
  .containercontactoinfo {
    flex-direction: column;
    align-items: center;
  }
}

.compartment {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
margin-bottom: 40px;
  width: 260px; /* Establece un ancho fijo para cada compartimento */
}

.icon {
  margin-bottom: 15px;
  background-color: #a0f000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;}


.containercontactoinfo strong {
  font-family: 'Kobern';
  font-size: 20px;
}

.letrafina {
  margin-top: 10px;
  font-family: 'GoldbillThin';
  font-weight: 1000; /* Puedes ajustar el valor según tus preferencias, como "600" o "700" */
  font-size: 22px;
}