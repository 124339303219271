.general {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 45px 0 40px;
  font-size: 15px;
  line-height: 24px;
  color: #d0d0d0;
  font-family: 'Lumier';
  text-align: center;
  z-index: 999; 
}
@media (max-width: 767px) {
  .general {
    bottom: env(safe-area-inset-bottom); /* Utiliza el espacio seguro para evitar solapamientos con la barra de navegación */
  }
}

/* Media query para ocultar el contenido cuando la altura del viewport es menor a 587px */
@media (max-height: 677px) {
  .general {
    display: none; /* Esto ocultará el contenido de .general */
  }
}



.general hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.general hr.small {
  margin: 20px 0;
}

.general h6 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
  font-family: 'Kobern';
  white-space: nowrap;

}

.general a {
  color: #737373;
}
.general a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}

.footer-links li {
  color: #a0f000;
  white-space: nowrap; /* Evita que los elementos se envuelvan */
}

.footer-links a {
  color: #737373;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.general .icons {
  text-align: right;
}
.general .icons a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}
.copyright-text {
  margin-bottom: 0px;
}
@media (max-width: 991px) {
  .general [class^='col-'] {
    margin-bottom: 30px;
  }
}



.icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap; 
  justify-content: center; /* Centrar los elementos en dispositivos móviles */
}
@media (max-width: 767px) {
  .icons {
    justify-content: center; /* Centrar los elementos en dispositivos móviles */
  }
}

.social-icons li {
  margin-right: 5px; /* Ajusta el espacio entre los iconos */
}

@media (max-width: 800px) {
  .social-icons li {
    text-align: center; /* Centrar el texto en pantallas más pequeñas */
  }
}

.icons li.title {
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.icons a {
  background-color: #eceeef;
  color: #ffffff;
  font-size: 20px;
  display: inline-block;

  text-align: center;

  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.icons a:active,
.icons a:focus,
.icons a:hover {
  color: #ffffff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.icons a.facebook:hover {
  background-color: #3b5998;
}
.icons a.twitter:hover {
  background-color: #00aced;
}
.icons a.linkedin:hover {
  background-color: #007bb6;
}
.icons a.dribbble:hover {
  background-color: #ea4c89;
}
@media (max-width: 767px) {
  .icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

.icons a:hover {
  color: #ffffff;
  background-color: #29aafe;
}

.icons.size-sm a:hover {
  color: #fff;
  background-color: #29aafe;
}

.icons a.facebook:hover {
  background-color: #3b5998;
}

.icons a.twitter:hover {
  background-color: #00aced;
}

.icons a.instagram:hover {
  background-color: #e4405f; /* Puedes ajustar el color según tus necesidades */
}

.icons a.youtube:hover {
  background-color: #c4302b; /* Puedes ajustar el color según tus necesidades */
}

.icons a.whatsapp:hover {
  background-color: #25d366;
}

.icons a.facebook {
  background-color: #3b589838;
}

.icons a.twitter {
  background-color: #00aeed38;
}

.icons a.instagram {
  background-color: #e4405e3f; /* Puedes ajustar el color según tus necesidades */
}

.icons a.youtube {
  background-color: #c4302b28; /* Puedes ajustar el color según tus necesidades */
}

.icons a.whatsapp {
  background-color: #25d36527;
}