body {
  margin: 0;
  margin-top: 120px!important;
  padding: 0;
  min-height: 100%;
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: auto;


 /* Color del fondo de la página */
  background-color: #ffffff !important;  

}

body::before {
  content: ''; /* Necesario para que el pseudo-elemento se muestre */
  display: block; /* Hace que el pseudo-elemento sea un bloque */
  position: absolute; /* Posicionamiento absoluto respecto al body */
  width: 100%; /* Ancho total de la página */
  height: 120px; /* Altura de 120px */
  background-color: #000; /* Color de fondo negro */
  top: 0; /* Alinea en la parte superior del body */
  left: 0; /* Alinea a la izquierda del body */
  z-index: -1; /* Se asegura de que esté detrás de otros elementos */
}



code {
  
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 4px; 



}

body::-webkit-scrollbar-track {
  background-color: #333333;
  border-radius: 10px;

}

body::-webkit-scrollbar-thumb {

  background-color: #a0f000;
  border-radius: 10px;
  border: 1px solid #02679d;

}

body::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;

}



