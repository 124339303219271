
.circle {
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);

	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
  }

  @media (max-width: 768px) {
    .circle {
		
		top: 120%;
	  
    }
}
  
  .logotext {
	position: absolute;
	width: 140px;
	height: 140px;
	background-size: cover;
	border-radius: 50%;
	background-position: center;
  }
  
  .textcircle {
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: consolas;
    color: #e4e4e4;
    font-size: 11px;
    animation: textRotation 38s linear infinite, fadeInOut 8s forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (max-width: 768px) {
    .textcircle {
		font-size: 7px;
       bottom: 50px;
	  
    }
}

  @keyframes textRotation {
	to {
	  transform: rotate(-360deg);
	}
  }

  @keyframes fadeInOut {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }
  
  .textcircle span {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: -2;
  }