.site-footer {
  background: linear-gradient(145deg, #0f1a20, #2E5868);
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #d0d0d0;
  padding-top: 70px;
  font-family: 'Lumier';
  z-index: 2;
}

@media (max-width: 800px) {
  .site-footer {
    text-align: center; /* Centrar el texto en pantallas más pequeñas */
  }
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}

.site-footer h6 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
  font-family: 'Kobern';
  white-space: nowrap;

}

.site-footer a {
  color: #737373;
}
.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}

.footer-links li {
  color: #a0f000;
  white-space: nowrap; /* Evita que los elementos se envuelvan */
}

.footer-links a {
  color: #737373;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.site-footer .social-icons a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}
.copyright-text {
  margin-bottom: 0px;
}
@media (max-width: 991px) {
  .site-footer [class^='col-'] {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap; 
}
@media (max-width: 767px) {
  .social-icons {
    justify-content: center; /* Centrar los elementos en dispositivos móviles */
  }
}

.social-icons li {
  margin-right: 5px; /* Ajusta el espacio entre los iconos */
}

@media (max-width: 800px) {
  .social-icons li {
    text-align: center; /* Centrar el texto en pantallas más pequeñas */
  }
}

.social-icons li.title {
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #ffffff;
  font-size: 20px;
  display: inline-block;

  text-align: center;

  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #ffffff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.twitter:hover {
  background-color: #00aced;
}
.social-icons a.linkedin:hover {
  background-color: #007bb6;
}
.social-icons a.dribbble:hover {
  background-color: #ea4c89;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

.social-icons a:hover {
  color: #ffffff;
  background-color: #29aafe;
}

.social-icons.size-sm a:hover {
  color: #fff;
  background-color: #29aafe;
}

.social-icons a.facebook:hover {
  background-color: #3b5998;
}

.social-icons a.twitter:hover {
  background-color: #00aced;
}

.social-icons a.instagram:hover {
  background-color: #e4405f; /* Puedes ajustar el color según tus necesidades */
}

.social-icons a.youtube:hover {
  background-color: #c4302b; /* Puedes ajustar el color según tus necesidades */
}

.social-icons a.whatsapp:hover {
  background-color: #25d366;
}

.social-icons a.facebook {
  background-color: #3b589838;
}

.social-icons a.twitter {
  background-color: #00aeed38;
}

.social-icons a.instagram {
  background-color: #e4405e3f; /* Puedes ajustar el color según tus necesidades */
}

.social-icons a.youtube {
  background-color: #c4302b28; /* Puedes ajustar el color según tus necesidades */
}

.social-icons a.whatsapp {
  background-color: #25d36527;
}
