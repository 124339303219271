

.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 510px;
  
  font-family: 'Kobern';

}

.contact-form {
  background-color: #F0F0F0;
  border-radius: 2px;
  box-shadow: 0 0 px rgba(0, 0, 0, 0.1);
  padding: 42px;
  max-width: 700px;
  font-size: 18px;
  transform: scale(0.8);
  margin-top: -160px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Ajusta los valores según tu preferencia */
 

  @media (max-width: 768px) {
    transform: scale(0.9);
    padding: 10px;
    max-width: 100%!important;
    margin-top: 0px;
    margin-bottom: 50px;
  
   }
   
}




h2 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  font-family: 'RNSMiles';
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

input,
textarea {
  color: #000000;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  transition: border-bottom-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
  background-color: #F0F0F0; /* Agregado para establecer el color de fondo */
}

input:required:invalid,
textarea:required:invalid {
  border-bottom-color: rgb(73, 73, 73);
}

input:focus,
textarea:focus {
  border-bottom-color: #a0f000;
}

.buttonformulario {
  background-color: #a0f000;
  color: #000000;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Kobern';
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  transition: background-color 0.3s ease; 
}

.buttonformulario:hover {
   font-family: 'Kobern';
  color: #F0F0F0;
  background-color: #0D1416;
  
}

.contact-form p {
  text-align: center;
  margin-top: 20px;
  color: #555;
  font-size: 22px;
  font-family: 'RNSMiles';
}