@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

:root{
 --bg:#1f202913;
    --bg-secundary:#0d141617;
    --color:#dfdee5;
}
  
*{
 box-sizing:border-box;
}

body{ 
    color:var(--color);
    background:var(--bg);
    font-family:'Source Sans Pro', 'Arial';
  
}

.titlelogin{
    font-family: 'Kobern'!important;
color: #111111;
margin: 20px;
font-size: 30px;

@media (max-width: 768px) {
  font-size: 25px;
}
}



.textlogin {
    font-size: 16px;
    font-weight: bold; /* Make the text bold */


    @media (max-width: 768px) {
      font-size: 15px;
    }
  }




.containerlogin{
  
    max-width: 780px;
    width: 95%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin:14px auto;
    margin-bottom: 130px;
    z-index: 29;
    opacity: 1; 



    @media (max-width: 768px) {
      margin-top: -40px;
    }


.containerlogin:not(:focus-within) {
  opacity: 0.7; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}






 /* Apply the fadeIn animation */
 animation: fadeIn 2s ease-in-out;


    &:not(:hover) {
        opacity: 1; /* Set opacity to 1 when not hovered */
    }



    .input{
        display:none;
      
    }

    .input{
        &:not(:checked){
          ~.cardlogin{
            .sign{
              left:0;
              z-index:1;
              font-family: 'RNSMiles';
            }
            .log{
              left:100%;
            } 
          }
        }
      
        &:checked{
            ~.toggle{
                .iconlogin{
                    .arrow{
                        left:50%;
                        transform:rotateY(180deg);
                    }
                }
            }
            ~.cardlogin{
              
              .sign{
                left:-100%;
                  color: #dfdee5;
              }
              .log{
                left:0;
                z-index:1;
              
              } 
            }
        }
    }
    
    .toggle{
        cursor:pointer;
        
        display:flex;
        justify-content:space-between;
        align-items:center;
        gap:1em;
        
        margin:1em 0;
        margin-bottom: 20px;
        
        .iconlogin{
            width:64px;
            height:32px;
          
            display:flex;
            align-items:center;
            
            position:relative;
            
            border-radius:64px;
            outline:2px solid var(--bg-secundary);
          
            
            .arrow{
                position:absolute;
                left:0;
                color: #010004;
                // background:var(--bg-secundary);
                border-radius:50%;
                background-color: #a0f000;
                transition:all ease 0.75s;
            }
        }
    }
    
    .cardlogin{

      min-height: 330px;
        max-width:480px;
        width:100%;
        position:relative;
        border-radius:10px;
       
        background:var(--bg-secundary);
        
        overflow:hidden;
      
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Ajusta los valores según tu preferencia */
      
        
        .contentlogin{
            width:100%;
          
          
            display:flex;
            flex-direction:column;
            justify-content:flex-start;
            align-items:center;
          
            position:absolute;
          
            transition:all ease 1s;
          
            .titlelogin{
           
                font-weight:600;
              
            
            }
            



            .fields{
                width:100%;
                padding:0 2em;
                .field{
                
                    display:flex;
                    align-items:center;
                  
                    margin:0.5em 0;
                  
                    border-radius:10px;
                    background:var(--bg);
                    width:100%;
                
                    
                    input:-webkit-autofill {
                        border-radius:10px;
              
                        box-shadow: 0 0 0 1000px transparent inset;
                    }

                    input {
                      color: #010004;
                      width: 100%;
                
                      font-weight: bold;



                      width: 100%; /* Ancho del input para que coincida con otros inputs del formulario */
                 
                      border-radius: 10px; /* Bordes redondeados */
                      border: 1px solid #a0f000; /* Borde con el color del botón de enviar */
                     

               
                    }
                }
            }
            




            .submitlogin{
            
                width:100%;
                text-align:center;
                margin:1em 0;
                

                .buttonsubmitlogin2 {
                  font-family: 'Kobern'; /* Fuente para el botón */
                    background-color: #a0f000;
                    cursor: pointer;
                    width: 65%;
                    border: none;
                    outline: none;
                    padding: 0.5em 1.5em;
                    border-radius: 10px;
                    box-shadow: 0 6px 12px -4px #ffffff;
                    transition: all ease 0.125s;
                    &:hover {
                      background-color: #000000;
                      color: #ffffff;
                      outline: 4px solid #ffffff40;
                  }
                  }


                .buttonsubmitlogin {
                  font-family: 'Kobern'; /* Fuente para el botón */
                    background-color: #a0f000;
                    cursor: pointer;
                    width: 75%;
                    border: none;
                    outline: none;
                    padding: 0.5em 1.5em;
                    border-radius: 10px;
                    box-shadow: 0 6px 12px -4px #ffffff;
                    transition: all ease 0.125s;
                   
                  
                       
                    &:hover {
                        background-color: #000000;
                        color: #ffffff;
                        outline: 4px solid #ffffff40;
                    }




                    
                }
            }
        }
    }
}


.modallogin {
    z-index: 2000;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.744);
    width: 500px;
    height: 260px;
    padding: 20px;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    font-family: 'Kobern';
    font-size: 20px;
  
    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; // Añade este estilo para centrar verticalmente
  
      .icon {
        background-color: #a0f000;
        border-radius: 50%;
        padding: 10px;
        // Ajusta el margen según sea necesario
      }
  
      p {
        margin-top: 10px;
      }
  
      button {
        margin-top: 15px;
      }
    }
  }
  
  .modallogin {
    z-index: 2000;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 20px;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    font-family: 'Lumier';
    font-size: 17px;
  
    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .icon {
        border-radius: 50%;
        padding: 10px;
        margin-bottom: 10px;
        margin-top: 60px;
      }
  
      p {
        margin-top: 10px;
      }
  
      button {
        margin-top: 15px;
      }
    }
  }
  
  .modallogin.visible {
    display: block;
  }
  
  .modallogin.error {
    background-color: #ff8c00; /* Cambia el color de fondo para indicar error */
    
    .modal-content {
      .icon {
        background-color: #ff8c00; /* Cambia el color del icono para indicar error */
      }
    }
  }




  .input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%; 
  }
  
  .eye-icon {
    position: absolute;
    right: 10px; /* Ajusta según sea necesario */
    cursor: pointer;
  }
  
  .iconlogin {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ajusta esto según el tamaño de tu contenedor */
    width: 14%; /* Ajusta esto según el tamaño de tu contenedor */
  }




  /* Estilo para el botón de ¿Olvidaste tu contraseña? */
.forgot-password {
  text-align: center; /* Centrar el botón dentro de su contenedor */
  margin-top: 1em; /* Margen en la parte superior para separarlo del resto del formulario */
}

/* Estilo para el botón de Enviar correo de recuperación */
.reset-password-form {
  text-align: center; /* Centrar los elementos del formulario de recuperación */
  margin-top: 1em; /* Margen en la parte superior */
}

.reset-password-form input {
  width: 100%; /* Ancho del input para que coincida con otros inputs del formulario */
  padding: 0.5em; /* Espaciado interno para el input */
  border-radius: 10px; /* Bordes redondeados */
  border: 2px solid #a0f000; /* Borde con el color del botón de enviar */
  

}

.reset-password-form button {
  font-family: 'Kobern'; /* Fuente para el botón */
  background-color: #a0f000; /* Color de fondo del botón */
  cursor: pointer;
  width: 100%; /* Ancho del botón para que coincida con el input */
  border: none;
  outline: none;
  padding: 0.5em 1.5em;
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 6px 12px -4px #ffffff; /* Sombra para el botón */
  transition: all ease 0.125s;
  margin-top: 8px;
}

.reset-password-form button:hover {
  background-color: #000000; /* Cambiar el color de fondo al pasar el mouse */
  color: #ffffff; /* Cambiar el color del texto al pasar el mouse */
  
}

.forgot-password-button {
  background: none;
  color: rgb(0, 0, 0); /* O el color que prefieras para el texto */
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  width: 75%!important;
}

/* Establecer el tamaño de fuente solo para dispositivos móviles */
@media (max-width: 768px) {
  .forgot-password-button {
    font-size: 13px;
  }
}

.forgot-password-button:hover,
.forgot-password-button:focus {
  text-decoration: none; /* Efecto al pasar el ratón o enfocar */
}